/**
 * This service uploads large files in chunks to Azure Blob Storage.
 *
 * The service takes a file and a URL as input and uploads the file in chunks. Once
 * all the chunks are uploaded, the service emits a 'complete' event.
 *
 * @author: Lewis MacCallum
 * @version: 1.0
 * @param {File} file - The file to upload.
 * @param {string} url - The URL to upload the file to.
 * @returns {EventEmitter} - An event emitter that emits a 'complete' event when the upload is complete.
 */

import axios from "axios";
import EventEmitter from "events";

export default class LargeFileUpload extends EventEmitter {
  blockids = [];

  constructor(file, url) {
    super();
    this.file = file;
    this.url = url;
  }

  async upload() {
    const fileSize = this.file.size;
    const chunkSize = 4 * 1024 * 1024;
    const numberOfChunks = Math.ceil(fileSize / chunkSize);

    console.log(`Uploading ${this.file.name} in ${numberOfChunks} chunks.`);
    let start, end, chunk;
    for (let i = 0; i < numberOfChunks; i++) {
      start = i * chunkSize;
      end = Math.min(fileSize, start + chunkSize);
      chunk = this.file.slice(start, end);
      await this.uploadChunk(chunk, i + 1, numberOfChunks);
      this.emit("progress", ((i / numberOfChunks) * 100).toFixed(2));
    }
  }

  async uploadChunk(chunk, chunkNumber, totalChunks) {
    let blockid = Buffer.from(chunkNumber.toString().padStart(5, "0")).toString(
      "base64"
    );
    this.blockids.push(blockid);
    await axios.put(this.url, chunk, {
      headers: {
        "Content-Type": "application/octet-stream",
        "x-ms-version": "2023-11-03",
        Accept: "application/json, application/xml",
        "x-ms-blob-type": "BlockBlob",
        "x-ms-date": new Date().toUTCString(),
      },
      params: {
        blockid: blockid,
        comp: "block",
      },
    });
    console.log(
      `Uploaded chunk ${chunkNumber}/${totalChunks} (${blockid}) (${
        (chunkNumber / totalChunks) * 100
      }).`
    );
    if (chunkNumber === totalChunks) {
      this.commitBlocks();
    }
  }

  async commitBlocks() {
    let body = '<?xml version="1.0" encoding="utf-8"?><BlockList>';
    this.blockids.forEach((blockid) => {
      body += `<Latest>${blockid}</Latest>`;
    });
    body += "</BlockList>";

    await axios.put(this.url, body, {
      headers: {
        "Content-Type": "text/plain; charset=UTF-8",
        "x-ms-version": "2023-11-03",
        Accept: "application/json, application/xml",
        "x-ms-blob-content-type": this.file.type,
        "x-ms-date": new Date().toUTCString(),
      },
      params: {
        comp: "blocklist",
      },
    });

    this.emit("complete");
  }
}
